import createReactComponent from '../createReactComponent';
export default createReactComponent(
  'navigation-exclamation',
  'IconNavigationExclamation',
  [
    [
      'path',
      {
        d: 'M16.535 12.832l-4.535 -9.832l-7.97 17.275c-.07 .2 -.017 .424 .135 .572c.15 .148 .374 .193 .57 .116l7.265 -2.463c1.38 .468 2.416 .82 3.107 1.053',
        key: 'svg-0',
      },
    ],
    ['path', { d: 'M19 16v3', key: 'svg-1' }],
    ['path', { d: 'M19 22v.01', key: 'svg-2' }],
  ],
);
