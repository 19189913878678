import createReactComponent from '../createReactComponent';
export default createReactComponent('location-bolt', 'IconLocationBolt', [
  [
    'path',
    {
      d: 'M13.05 20.1l-3.05 -6.1l-7 -3.5a.55 .55 0 0 1 0 -1l18 -6.5l-3.312 9.173',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M19 16l-2 3h4l-2 3', key: 'svg-1' }],
]);
