import createReactComponent from '../createReactComponent';
export default createReactComponent('location-share', 'IconLocationShare', [
  [
    'path',
    {
      d: 'M12 18l-2 -4l-7 -3.5a.55 .55 0 0 1 0 -1l18 -6.5l-3.616 10.015',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M16 22l5 -5', key: 'svg-1' }],
  ['path', { d: 'M21 21.5v-4.5h-4.5', key: 'svg-2' }],
]);
