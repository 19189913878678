import createReactComponent from '../createReactComponent';
export default createReactComponent('location-pause', 'IconLocationPause', [
  [
    'path',
    {
      d: 'M13.02 20.04l-3.02 -6.04l-7 -3.5a.55 .55 0 0 1 0 -1l18 -6.5l-3.634 10.062',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M17 17v5', key: 'svg-1' }],
  ['path', { d: 'M21 17v5', key: 'svg-2' }],
]);
