import createReactComponent from '../createReactComponent';
export default createReactComponent('location-down', 'IconLocationDown', [
  [
    'path',
    {
      d: 'M12 18l-2 -4l-7 -3.5a.55 .55 0 0 1 0 -1l18 -6.5l-3.328 9.217',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M19 16v6', key: 'svg-1' }],
  ['path', { d: 'M22 19l-3 3l-3 -3', key: 'svg-2' }],
]);
