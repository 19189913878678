import createReactComponent from '../createReactComponent';
export default createReactComponent('music-heart', 'IconMusicHeart', [
  ['path', { d: 'M3 17a3 3 0 1 0 6 0a3 3 0 0 0 -6 0', key: 'svg-0' }],
  ['path', { d: 'M9 17v-13h10v7', key: 'svg-1' }],
  ['path', { d: 'M9 8h10', key: 'svg-2' }],
  [
    'path',
    {
      d: 'M18 22l3.35 -3.284a2.143 2.143 0 0 0 .005 -3.071a2.242 2.242 0 0 0 -3.129 -.006l-.224 .22l-.223 -.22a2.242 2.242 0 0 0 -3.128 -.006a2.143 2.143 0 0 0 -.006 3.071l3.355 3.296z',
      key: 'svg-3',
    },
  ],
]);
