import createReactComponent from '../createReactComponent';
export default createReactComponent('navigation', 'IconNavigation', [
  [
    'path',
    {
      d: 'M12 18.5l7.265 2.463c.196 .077 .42 .032 .57 -.116a.548 .548 0 0 0 .134 -.572l-7.969 -17.275l-7.97 17.275c-.07 .2 -.017 .424 .135 .572c.15 .148 .374 .193 .57 .116l7.265 -2.463',
      key: 'svg-0',
    },
  ],
]);
