import createReactComponent from '../createReactComponent';
export default createReactComponent(
  'layout-grid-remove',
  'IconLayoutGridRemove',
  [
    [
      'path',
      {
        d: 'M4 5a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v4a1 1 0 0 1 -1 1h-4a1 1 0 0 1 -1 -1v-4z',
        key: 'svg-0',
      },
    ],
    [
      'path',
      {
        d: 'M14 5a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v4a1 1 0 0 1 -1 1h-4a1 1 0 0 1 -1 -1v-4z',
        key: 'svg-1',
      },
    ],
    [
      'path',
      {
        d: 'M4 15a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v4a1 1 0 0 1 -1 1h-4a1 1 0 0 1 -1 -1v-4z',
        key: 'svg-2',
      },
    ],
    ['path', { d: 'M14 17h6', key: 'svg-3' }],
  ],
);
