import createReactComponent from '../createReactComponent';
export default createReactComponent('octahedron', 'IconOctahedron', [
  [
    'path',
    {
      d: 'M12.859 21.652l8.845 -8.949a.984 .984 0 0 0 0 -1.407l-8.845 -8.948a1.233 1.233 0 0 0 -1.718 0l-8.845 8.949a.984 .984 0 0 0 0 1.407l8.845 8.949a1.234 1.234 0 0 0 1.718 -.001z',
      key: 'svg-0',
    },
  ],
  [
    'path',
    {
      d: 'M2 12c.004 .086 .103 .178 .296 .246l8.845 2.632c.459 .163 1.259 .163 1.718 0l8.845 -2.632c.195 -.07 .294 -.156 .296 -.243',
      key: 'svg-1',
    },
  ],
  ['path', { d: 'M12 2.12v19.76', key: 'svg-2' }],
]);
