import createReactComponent from '../createReactComponent';
export default createReactComponent('location-dollar', 'IconLocationDollar', [
  [
    'path',
    {
      d: 'M13.08 20.162l-3.08 -6.162l-7 -3.5a.55 .55 0 0 1 0 -1l18 -6.5l-2.55 7.063',
      key: 'svg-0',
    },
  ],
  [
    'path',
    {
      d: 'M21 15h-2.5a1.5 1.5 0 0 0 0 3h1a1.5 1.5 0 0 1 0 3h-2.5',
      key: 'svg-1',
    },
  ],
  ['path', { d: 'M19 21v1m0 -8v1', key: 'svg-2' }],
]);
