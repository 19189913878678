import createReactComponent from '../createReactComponent';
export default createReactComponent('navigation-check', 'IconNavigationCheck', [
  [
    'path',
    {
      d: 'M17.487 14.894l-5.487 -11.894l-7.97 17.275c-.07 .2 -.017 .424 .135 .572c.15 .148 .374 .193 .57 .116l6.275 -2.127',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M15 19l2 2l4 -4', key: 'svg-1' }],
]);
