import createReactComponent from '../createReactComponent';
export default createReactComponent('navigation-cog', 'IconNavigationCog', [
  [
    'path',
    {
      d: 'M16.387 12.51l-4.387 -9.51l-7.97 17.275c-.07 .2 -.017 .424 .135 .572c.15 .148 .374 .193 .57 .116l7.265 -2.463',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M19.001 19m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0', key: 'svg-1' }],
  ['path', { d: 'M19.001 15.5v1.5', key: 'svg-2' }],
  ['path', { d: 'M19.001 21v1.5', key: 'svg-3' }],
  ['path', { d: 'M22.032 17.25l-1.299 .75', key: 'svg-4' }],
  ['path', { d: 'M17.27 20l-1.3 .75', key: 'svg-5' }],
  ['path', { d: 'M15.97 17.25l1.3 .75', key: 'svg-6' }],
  ['path', { d: 'M20.733 20l1.3 .75', key: 'svg-7' }],
]);
