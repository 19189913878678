import createReactComponent from '../createReactComponent';
export default createReactComponent(
  'navigation-search',
  'IconNavigationSearch',
  [
    [
      'path',
      {
        d: 'M15.876 11.403l-3.876 -8.403l-7.97 17.275c-.07 .2 -.017 .424 .135 .572c.15 .148 .374 .193 .57 .116l6.29 -2.132',
        key: 'svg-0',
      },
    ],
    ['path', { d: 'M18 18m-3 0a3 3 0 1 0 6 0a3 3 0 1 0 -6 0', key: 'svg-1' }],
    ['path', { d: 'M20.2 20.2l1.8 1.8', key: 'svg-2' }],
  ],
);
