import createReactComponent from '../createReactComponent';
export default createReactComponent(
  'location-question',
  'IconLocationQuestion',
  [
    [
      'path',
      {
        d: 'M14.5 21a.55 .55 0 0 1 -1 0l-3.5 -7l-7 -3.5a.55 .55 0 0 1 0 -1l18 -6.5l-2.967 8.215',
        key: 'svg-0',
      },
    ],
    ['path', { d: 'M19 22v.01', key: 'svg-1' }],
    [
      'path',
      {
        d: 'M19 19a2.003 2.003 0 0 0 .914 -3.782a1.98 1.98 0 0 0 -2.414 .483',
        key: 'svg-2',
      },
    ],
  ],
);
