import createReactComponent from '../createReactComponent';
export default createReactComponent('map-question', 'IconMapQuestion', [
  ['path', { d: 'M15 20l-6 -3l-6 3v-13l6 -3l6 3l6 -3v7.5', key: 'svg-0' }],
  ['path', { d: 'M9 4v13', key: 'svg-1' }],
  ['path', { d: 'M15 7v5.5', key: 'svg-2' }],
  ['path', { d: 'M19 22v.01', key: 'svg-3' }],
  [
    'path',
    {
      d: 'M19 19a2.003 2.003 0 0 0 .914 -3.782a1.98 1.98 0 0 0 -2.414 .483',
      key: 'svg-4',
    },
  ],
]);
