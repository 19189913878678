import createReactComponent from '../createReactComponent';
export default createReactComponent('navigation-north', 'IconNavigationNorth', [
  [
    'path',
    {
      d: 'M16.54 19.977a.34 .34 0 0 0 .357 -.07a.33 .33 0 0 0 .084 -.35l-4.981 -10.557l-4.982 10.557a.33 .33 0 0 0 .084 .35a.34 .34 0 0 0 .357 .07l4.541 -1.477l4.54 1.477z',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M12 3v2', key: 'svg-1' }],
]);
