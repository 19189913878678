import createReactComponent from '../createReactComponent';
export default createReactComponent('location-code', 'IconLocationCode', [
  [
    'path',
    {
      d: 'M11.505 17.01l-1.505 -3.01l-7 -3.5a.55 .55 0 0 1 0 -1l18 -6.5l-3.677 10.184',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M20 21l2 -2l-2 -2', key: 'svg-1' }],
  ['path', { d: 'M17 17l-2 2l2 2', key: 'svg-2' }],
]);
