import createReactComponent from '../createReactComponent';
export default createReactComponent('location-check', 'IconLocationCheck', [
  [
    'path',
    {
      d: 'M11.512 17.023l-1.512 -3.023l-7 -3.5a.55 .55 0 0 1 0 -1l18 -6.5l-4.45 12.324',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M15 19l2 2l4 -4', key: 'svg-1' }],
]);
