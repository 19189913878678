import createReactComponent from '../createReactComponent';
export default createReactComponent('music-dollar', 'IconMusicDollar', [
  ['path', { d: 'M3 17a3 3 0 1 0 6 0a3 3 0 0 0 -6 0', key: 'svg-0' }],
  ['path', { d: 'M9 17v-13h10v6', key: 'svg-1' }],
  ['path', { d: 'M9 8h10', key: 'svg-2' }],
  [
    'path',
    {
      d: 'M21 15h-2.5a1.5 1.5 0 0 0 0 3h1a1.5 1.5 0 0 1 0 3h-2.5',
      key: 'svg-3',
    },
  ],
  ['path', { d: 'M19 21v1m0 -8v1', key: 'svg-4' }],
]);
