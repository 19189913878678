import createReactComponent from '../createReactComponent';
export default createReactComponent(
  'medical-cross-circle',
  'IconMedicalCrossCircle',
  [
    ['path', { d: 'M3 12a9 9 0 1 0 18 0a9 9 0 0 0 -18 0', key: 'svg-0' }],
    ['path', { d: 'M12 8v8', key: 'svg-1' }],
    ['path', { d: 'M15.5 10l-7 4', key: 'svg-2' }],
    ['path', { d: 'M15.5 14l-7 -4', key: 'svg-3' }],
  ],
);
