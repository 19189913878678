import createReactComponent from '../createReactComponent';
export default createReactComponent('navigation-bolt', 'IconNavigationBolt', [
  [
    'path',
    {
      d: 'M16.559 12.882l-4.559 -9.882l-7.97 17.275c-.07 .2 -.017 .424 .135 .572c.15 .148 .374 .193 .57 .116l7.265 -2.463l1.036 .351',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M19 16l-2 3h4l-2 3', key: 'svg-1' }],
]);
