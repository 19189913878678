import createReactComponent from '../createReactComponent';
export default createReactComponent('location-minus', 'IconLocationMinus', [
  [
    'path',
    {
      d: 'M12 18l-2 -4l-7 -3.5a.55 .55 0 0 1 0 -1l18 -6.5l-4.347 12.038',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M16 19h6', key: 'svg-1' }],
]);
