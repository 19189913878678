import createReactComponent from '../createReactComponent';
export default createReactComponent(
  'navigation-discount',
  'IconNavigationDiscount',
  [
    [
      'path',
      {
        d: 'M16.43 12.603l-4.43 -9.603l-7.97 17.275c-.07 .2 -.017 .424 .135 .572c.15 .148 .374 .193 .57 .116l7.265 -2.463l1.272 .431',
        key: 'svg-0',
      },
    ],
    ['path', { d: 'M16 21l5 -5', key: 'svg-1' }],
    ['path', { d: 'M21 21v.01', key: 'svg-2' }],
    ['path', { d: 'M16 16v.01', key: 'svg-3' }],
  ],
);
