import createReactComponent from '../createReactComponent';
export default createReactComponent(
  'navigation-dollar',
  'IconNavigationDollar',
  [
    [
      'path',
      {
        d: 'M15.945 11.551l-3.945 -8.551l-7.97 17.275c-.07 .2 -.017 .424 .135 .572c.15 .148 .374 .193 .57 .116l7.265 -2.463l1.594 .54',
        key: 'svg-0',
      },
    ],
    [
      'path',
      {
        d: 'M21 15h-2.5a1.5 1.5 0 0 0 0 3h1a1.5 1.5 0 0 1 0 3h-2.5',
        key: 'svg-1',
      },
    ],
    ['path', { d: 'M19 21v1m0 -8v1', key: 'svg-2' }],
  ],
);
