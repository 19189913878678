import createReactComponent from '../createReactComponent';
export default createReactComponent('navigation-pause', 'IconNavigationPause', [
  [
    'path',
    {
      d: 'M16.666 13.114l-4.666 -10.114l-7.97 17.275c-.07 .2 -.017 .424 .135 .572c.15 .148 .374 .193 .57 .116l7.265 -2.463l1.056 .358',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M17 17v5', key: 'svg-1' }],
  ['path', { d: 'M21 17v5', key: 'svg-2' }],
]);
