import createReactComponent from '../createReactComponent';
export default createReactComponent('navigation-minus', 'IconNavigationMinus', [
  [
    'path',
    {
      d: 'M17.5 15c-1.232 -2.67 -3.065 -6.67 -5.5 -12l-7.97 17.275c-.07 .2 -.017 .424 .135 .572c.15 .148 .374 .193 .57 .116l7.265 -2.463',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M16 19h6', key: 'svg-1' }],
]);
