import createReactComponent from '../createReactComponent';
export default createReactComponent(
  'navigation-cancel',
  'IconNavigationCancel',
  [
    [
      'path',
      {
        d: 'M16.371 12.476l-4.371 -9.476l-7.97 17.275c-.07 .2 -.017 .424 .135 .572c.15 .148 .374 .193 .57 .116l7.265 -2.463',
        key: 'svg-0',
      },
    ],
    ['path', { d: 'M19 19m-3 0a3 3 0 1 0 6 0a3 3 0 1 0 -6 0', key: 'svg-1' }],
    ['path', { d: 'M17 21l4 -4', key: 'svg-2' }],
  ],
);
