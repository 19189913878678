import createReactComponent from '../createReactComponent';
export default createReactComponent(
  'navigation-question',
  'IconNavigationQuestion',
  [
    [
      'path',
      {
        d: 'M16.081 11.847l-4.081 -8.847l-7.97 17.275c-.07 .2 -.017 .424 .135 .572c.15 .148 .374 .193 .57 .116l7.265 -2.463c1.35 .458 2.362 .8 3.037 1.03',
        key: 'svg-0',
      },
    ],
    ['path', { d: 'M19 22v.01', key: 'svg-1' }],
    [
      'path',
      {
        d: 'M19 19a2.003 2.003 0 0 0 .914 -3.782a1.98 1.98 0 0 0 -2.414 .483',
        key: 'svg-2',
      },
    ],
  ],
);
