import createReactComponent from '../createReactComponent';
export default createReactComponent('map-dollar', 'IconMapDollar', [
  ['path', { d: 'M13 19l-4 -2l-6 3v-13l6 -3l6 3l6 -3v6.5', key: 'svg-0' }],
  ['path', { d: 'M9 4v13', key: 'svg-1' }],
  ['path', { d: 'M15 7v5', key: 'svg-2' }],
  [
    'path',
    {
      d: 'M21 15h-2.5a1.5 1.5 0 0 0 0 3h1a1.5 1.5 0 0 1 0 3h-2.5',
      key: 'svg-3',
    },
  ],
  ['path', { d: 'M19 21v1m0 -8v1', key: 'svg-4' }],
]);
