import createReactComponent from '../createReactComponent';
export default createReactComponent('octahedron-off', 'IconOctahedronOff', [
  [
    'path',
    {
      d: 'M6.771 6.77l-4.475 4.527a.984 .984 0 0 0 0 1.407l8.845 8.949a1.234 1.234 0 0 0 1.718 -.001l4.36 -4.412m2.002 -2.025l2.483 -2.512a.984 .984 0 0 0 0 -1.407l-8.845 -8.948a1.233 1.233 0 0 0 -1.718 0l-2.375 2.403',
      key: 'svg-0',
    },
  ],
  [
    'path',
    {
      d: 'M2 12c.004 .086 .103 .178 .296 .246l8.845 2.632c.459 .163 1.259 .163 1.718 0l1.544 -.46m3.094 -.92l4.207 -1.252c.195 -.07 .294 -.156 .296 -.243',
      key: 'svg-1',
    },
  ],
  ['path', { d: 'M12 2.12v5.88m0 4v9.88', key: 'svg-2' }],
  ['path', { d: 'M3 3l18 18', key: 'svg-3' }],
]);
