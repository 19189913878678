import createReactComponent from '../createReactComponent';
export default createReactComponent(
  'location-discount',
  'IconLocationDiscount',
  [
    [
      'path',
      {
        d: 'M12.797 19.595l-2.797 -5.595l-7 -3.5a.55 .55 0 0 1 0 -1l18 -6.5l-3.548 9.826',
        key: 'svg-0',
      },
    ],
    ['path', { d: 'M16 21l5 -5', key: 'svg-1' }],
    ['path', { d: 'M21 21v.01', key: 'svg-2' }],
    ['path', { d: 'M16 16v.01', key: 'svg-3' }],
  ],
);
