import createReactComponent from '../createReactComponent';
export default createReactComponent(
  'music-exclamation',
  'IconMusicExclamation',
  [
    ['path', { d: 'M3 17a3 3 0 1 0 6 0a3 3 0 0 0 -6 0', key: 'svg-0' }],
    ['path', { d: 'M9 17v-13h10v8', key: 'svg-1' }],
    ['path', { d: 'M9 8h10', key: 'svg-2' }],
    ['path', { d: 'M19 16v3', key: 'svg-3' }],
    ['path', { d: 'M19 22v.01', key: 'svg-4' }],
  ],
);
