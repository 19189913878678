import createReactComponent from '../createReactComponent';
export default createReactComponent('load-balancer', 'IconLoadBalancer', [
  ['path', { d: 'M12 13m-3 0a3 3 0 1 0 6 0a3 3 0 1 0 -6 0', key: 'svg-0' }],
  ['path', { d: 'M12 20m-1 0a1 1 0 1 0 2 0a1 1 0 1 0 -2 0', key: 'svg-1' }],
  ['path', { d: 'M12 16v3', key: 'svg-2' }],
  ['path', { d: 'M12 10v-7', key: 'svg-3' }],
  ['path', { d: 'M9 6l3 -3l3 3', key: 'svg-4' }],
  ['path', { d: 'M12 10v-7', key: 'svg-5' }],
  ['path', { d: 'M9 6l3 -3l3 3', key: 'svg-6' }],
  ['path', { d: 'M14.894 12.227l6.11 -2.224', key: 'svg-7' }],
  ['path', { d: 'M17.159 8.21l3.845 1.793l-1.793 3.845', key: 'svg-8' }],
  ['path', { d: 'M9.101 12.214l-6.075 -2.211', key: 'svg-9' }],
  ['path', { d: 'M6.871 8.21l-3.845 1.793l1.793 3.845', key: 'svg-10' }],
]);
