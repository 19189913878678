import createReactComponent from '../createReactComponent';
export default createReactComponent('location-search', 'IconLocationSearch', [
  [
    'path',
    {
      d: 'M11 16l-1 -2l-7 -3.5a.55 .55 0 0 1 0 -1l18 -6.5l-2.916 8.076',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M18 18m-3 0a3 3 0 1 0 6 0a3 3 0 1 0 -6 0', key: 'svg-1' }],
  ['path', { d: 'M20.2 20.2l1.8 1.8', key: 'svg-2' }],
]);
